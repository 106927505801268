import React from 'react';
import Tag from 'components/tag';
import './index.less';

const Loading = (props) => {
    return (
        <Tag.div className="user-center-loading">
            <Tag.span>{props.children}</Tag.span>
            <Tag.i className="user-center-loading__icon"></Tag.i>
        </Tag.div>
    );
};

export { Loading };