import { localStorage } from 'components/util';
import { Collect } from 'components/report';
import { getUrlParam } from 'components/href-helper';
/**
* 动态计算根字体
* 进入页面后判断有无默认字体比例设置，有认为是老年用户，直接设置
* 支持客户端自动传入字体模式， 客户端传入字体 优先级高于监听的变化
*/
export default function setRootFontSize() {
  const newFontRatio = getUrlParam('font_ratio');
  const oldFontRatio = localStorage.getItem('fontRatio');
  // 进入白名单页面需要清除缓存的字体设置
  const whitePathList = [
    /\/mobile(\/)?$/,
  ];
  const isWhitePath = whitePathList.some(item => item.test(location.pathname));
  if (isWhitePath && !newFontRatio && oldFontRatio) {
    localStorage.setItem('fontRatio', '');
  }
  if (newFontRatio) {
    localStorage.setItem('fontRatio', newFontRatio);
  } else if (!oldFontRatio) {
    // 没有检测到客户端字体（url & 缓存）则开启监听页面字体设置
    listenBodyStyleChange();
  }

  // 计算 and 设置字体
  recalc();
  // 监听resize事件
  const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
  window.addEventListener(resizeEvt, recalc, false);
}

// 旧屏幕尺寸适配逻辑
const recalc =  () => {
  const fontSizeRatio = localStorage.getItem('fontRatio')
  || localStorage.getItem('wxFontRatio')
  || 1;
  const docEl = document.documentElement;
  let baseFontSize = 50;
  // 设置根字体大小
  if (docEl.clientWidth <= 750) {
    baseFontSize = 100 * (docEl.clientWidth / 750);
  }

  // 数据需求， 放大倍数超过1.25的标记为老年老花用户
  if (fontSizeRatio > 1.25) {
    new Collect({ ea: '老年用户', ev: fontSizeRatio }).report();
  }
  docEl.style.fontSize = `${baseFontSize * fontSizeRatio}px`;
};

const adaptWxFont = () => {
  const ratio = Number.parseFloat(document.body.style.webkitTextSizeAdjust) / 100;
  if (!Number.isNaN(ratio)) {
    localStorage.setItem('wxFontRatio', ratio);
    recalc();
  }
};

/** 在微信中监听微信对字体设置的改变 */
const listenBodyStyleChange = function () {
  if (!window.MutationObserver) {
    return;
  }
  const targetNode = document.body;

  const config = { attributes: true, childList: false, subtree: false, attributeFilter: ['style'] };


  const callback = function (mutations) {
    mutations.some((mutation) => {
      if (mutation.type === 'attributes') {
        adaptWxFont();
        return true;
      }
      return false;
    });
  };

  // 创建body观察器实例
  const observer = new MutationObserver(callback);

  // 开始监听
  observer.observe(targetNode, config);
};
